import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';


const routes: Routes = [
  // ana sayfa
  { path: '', loadChildren: () => import('./components/pages/home/home.module').then(m => m.HomeModule), data: { breadcrumb: 'breadCrumb1' } },
  // hakkımızda
  { path: 'about', loadChildren: () => import('./components/pages/about/about.module').then(m => m.AboutModule), data: { breadcrumb: 'breadCrumb2' } },
  // Services
  { path: 'services', loadChildren: () => import('./components/pages/services/services.module').then(m => m.ServicesModule), data: { breadcrumb: 'breadCrumb3' } },
  { path: 'service-details', loadChildren: () => import('./components/pages/service-details/service-details.module').then(m => m.ServiceDetailsModule), data: { breadcrumb: 'Service Details' } },
  // galeri
  { path: 'gallery', loadChildren: () => import('./components/pages/gallery/gallery.module').then(m => m.GalleryModule), data: { breadcrumb: 'breadCrumb4' } },
  // neden türkiye
  { path: 'why-turkey', loadChildren: () => import('./components/pages/why-turkey/why-turkey.module').then(m => m.WhyTurkeyModule), data: { breadcrumb: 'breadCrumb5' } },
  // doktorlar
  { path: 'prof-dr-akin-ugras', loadChildren: () => import('./components/pages/akin-ugras/akin-ugras.module').then(m => m.AkinUgrasModule), data: { breadcrumb: 'breadCrumb6' } },
  { path: 'op-dr-sevda-ugras', loadChildren: () => import('./components/pages/sevda-ugras/sevda-ugras.module').then(m => m.SevdaUgrasModule), data: { breadcrumb: 'breadCrumb6' } },
  // Basında
  { path: 'basinda', loadChildren: () => import('./components/pages/basinda/basinda.module').then(m => m.BasindaModule), data: { breadcrumb: 'breadCrumb7' } },
  // 404
  { path: 'error', loadChildren: () => import('./components/pages/error/error.module').then(m => m.ErrorModule), data: { breadcrumb: 'breadCrumb8' } },
  // ekip
  { path: 'team', loadChildren: () => import('./components/pages/team/team.module').then(m => m.TeamModule), data: { breadcrumb: 'breadCrumb9' } },
  // blog
  { path: 'blog-standard', loadChildren: () => import('./components/pages/blog-standard/blog-standard.module').then(m => m.BlogStandardModule), data: { breadcrumb: 'breadCrumb11' } },
  { path: 'blog-details/:id', loadChildren: () => import('./components/pages/blog-details/blog-details.module').then(m => m.BlogDetailsModule), data: { breadcrumb: 'breadCrumb11' } },
  { path: 'blog/cat/:catId', loadChildren: () => import('./components/pages/blog-grid/blog-grid.module').then(m => m.BlogGridModule), data: { breadcrumb: 'breadCrumb11' } },
  { path: 'blog/tag/:tagId', loadChildren: () => import('./components/pages/blog-grid/blog-grid.module').then(m => m.BlogGridModule), data: { breadcrumb: 'breadCrumb11' } },
  { path: 'blog/author/:authorId', loadChildren: () => import('./components/pages/blog-grid/blog-grid.module').then(m => m.BlogGridModule), data: { breadcrumb: 'breadCrumb11' } },

  // iletisim
  { path: 'contact', loadChildren: () => import('./components/pages/contact/contact.module').then(m => m.ContactModule), data: { breadcrumb: 'breadCrumb10' } },
  { path: '**', loadChildren: () => import('./components/pages/error/error.module').then(m => m.ErrorModule), data: { breadcrumb: 'breadCrumb8' } }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload', useHash: true }, )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
